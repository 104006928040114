import Splide from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';

export default class SplideSlider {
    splideSlider: Splide;

    constructor() { }

    grid(identifier: string, padding: string) {
        const splide = new Splide(`#${identifier}`, {
            perPage: 2,
            perMove: 2,
            arrows: false,
            pagination: false,
            height: '1008px',
            gap: padding,
            grid: {
                dimensions: [[1, 1], [2, 1], [1, 1], [2, 1], [1, 1], [2, 1]],
                gap: {
                    row: padding,
                    col: padding
                },
            },
            breakpoints: {
                1920: {
                    height: '808px',
                },
                1200: {
                    height: '708px',
                },
                992: {
                    height: '608px',
                },
                767: {
                    perPage: 1,
                },
                580: {
                    height: '508px',
                },
            }
        }).mount({ Grid });

        console.log(splide)
    }

    carousel(identifier: string, pagination: boolean, padding: string) {
        new Splide(`#${identifier}`, {
            type: 'slide',
            pagination: pagination,
            perPage: 3,
            perMove: 1,
            arrows: false,
            gap: padding,
            padding: { right: '7rem' },
            breakpoints: {
                1370: {
                    perPage: 2,
                },
                992: {
                    perPage: 1,
                },
                767: {
                },
                580: {
                    padding: 0,
                    pagination: true,
                },
            }
        }).mount();
    }
}