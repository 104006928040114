import Splide from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
export default class IconGridBox {
    constructor(componentId:string) {
        new Splide(`#${componentId}`, {
            perMove: 1,
            arrows: false,
            pagination: false,
            drag: false,
            autoHeight: true,
            grid: {
                rows: 6,
                cols: 6,
            },
            breakpoints: {
                767: {
                    grid: {
                        rows: 9,
                        cols: 4,
                    }
                },
                580: {
                    grid: false,
                    drag: 'free',
                    snap: true,
                    fixedWidth: '180px',
                    autoHeight: true,
                    pagination: true,
                },
            }
        }).mount({ Grid });
    }
}