import { Country, State, City } from 'country-state-city';

export default class CountryStateCity {
    CountryInput: HTMLSelectElement;
    StateInput: HTMLSelectElement;
    CityInput: HTMLSelectElement;

    constructor() { }

    //setCountry(id: string) {
    //    var input = document.getElementById(id) as HTMLSelectElement;
    //    if (input) {
    //        this.CountryInput = input;
    //        this.CountryInput.addEventListener('change', (event) => {

    //        });
    //    }
    //}

    Countries() {
        return Country.getAllCountries();
    }

    States(country: string) {
        return State.getStatesOfCountry(country);
    }

    State(code: string, country: string) {
        return State.getStateByCodeAndCountry(code, country);
    }

    Cities(country: string) {
        return City.getCitiesOfCountry(country);
        //return City.getCitiesOfState(country, state);
    }

    City(country: string, state: string) {
        return City.getCitiesOfState(country, state);
    }
}
