let dotClass: string;

export default class Banner {
    constructor(_dotClass: string) {
        dotClass = _dotClass;

        $(document).ready(() => {
            $('.slides').slick('slickSetOption', {
                arrows: false,
                dots: true,
                dotsClass: `${dotClass}`,
                vertical: true,
                verticalSwiping: false,
                swipe: false,
                draggable: false,
                touchMove: false,
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 1,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        dots: false
                        }
                    }]
            }, true);
        });
    }
}