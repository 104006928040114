let slidesPerRow: number;
let clientIdNum: number;
let quoteIdNum: number;

export default class Slider {
    constructor(_slidesPerRow: number, _clientIdNum: number, _quoteIdNum: number) {
        slidesPerRow = _slidesPerRow;
        clientIdNum = _clientIdNum;
        quoteIdNum = _quoteIdNum;

            var client = document.getElementById(`client-slider${clientIdNum}`);
            if (client != null) {

                $(`#client-slider${clientIdNum}`).slick({
                    dots: true,
                    slidesToShow: slidesPerRow,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    arrows: false
                });
            }

            var testimonial = document.getElementById(`testimonial-slider${quoteIdNum}`);
            if (testimonial != null) {
                $(`#testimonial-slider${quoteIdNum}`).slick({
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    arrows: false
                });
            }
        
    }
}